<template>
	<page-container :page="page">
		<heading-image
			v-if="page && page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		/>

		<main-content>
			<template #content>
				<div id="wide-intro" class="columns column12">
					<h4 class="subtitle">
						<span>{{ page.subtitle }}</span>
					</h4>
					<h1>
						<span>{{ page.title || page.header }}</span>
						<span>{{ page.titleRight }}</span>
					</h1>
					<div v-parse-links v-html="page.content" />
				</div>
			</template>
		</main-content>

		<contentblocks v-if="page.contentblocks && page.contentblocks.length" :items="page.contentblocks" />

		<review-slider v-if="page.showReviewSlider" :header="defaults[locale].website.reviewsHeader" />

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<newsletter
			v-if="page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();
</script>

<style lang="scss" scoped>
:deep(#wide-intro) {
	margin: 0 auto;
	max-width: 900px;
}

:deep(#wide-intro) p {
	max-width: 100%;
}
</style>
